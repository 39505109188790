<template>
<div>
  <Header/>
  <b-container 
    style="max-width:95%; padding-top:70px;"
  >
    <div>
      <b-row>
        <b-col>
          <h1>
            マイページ
          </h1>
        </b-col>
        <b-col>
          <b-row>
            <p>期間絞り込み</p>
            <b-col>
              <small>From</small>
              <vuejs-datepicker 
                name="input_month"
                :language="ja"
                :format="DatePickerFormat"
                :initial-view="'year'"
                :minimum-view="'month'"
                :maximum-view="'year'"
                v-model="searchFrom"
              />
            </b-col>
            <b-col>
              <small>To</small>
              <vuejs-datepicker 
                name="input_month"
                :language="ja"
                :format="DatePickerFormat"
                :initial-view="'year'"
                :minimum-view="'month'"
                :maximum-view="'year'"
                v-model="searchTo"
              />
            </b-col>
            <b-col>
              <b-button 
                variant="primary" 
                @click="searchCount"
              >
                絞り込み
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <b-row 
      class="mt-2"
    >
      <b-row >
        <b-col>
          <b-card
            header="今月投稿写真数"
            style="height:100%;"
          >
            <b-card-title>
              {{ toMonth }}年{{ toDate }}月&nbsp;&nbsp;&nbsp;{{ monthPostCount }}件
            </b-card-title>
          </b-card>
        </b-col>

        <b-col >
          <b-card
            header="期間投稿写真数"
            style="height:100%;"
          >
            <b-row>
              <b-card-title>
                {{ totalPostCount }} 件
              </b-card-title>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row >
        <b-col>
          <b-card
            header="今月承認写真数"
            style="height:100%;"
          >
          <b-card-title>
            {{ toMonth }}年{{ toDate }}月&nbsp;&nbsp;&nbsp;{{ monthApplyingCount }}件
          </b-card-title>
          </b-card>
        </b-col>

        <b-col >
          <b-card
            header="期間承認写真数"
            style="height:100%;"
          >
            <b-row>
              <b-card-title>
                {{ totalApplyingCount }}件
              </b-card-title>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-row>

    <b-row 
      class="mt-2"
    >
      <b-col 
        md="2"
      >
        <select 
          class="form-select" 
          name="apploval" 
          v-model="searchApploval"
        >
          <option 
            v-for="(applo ,index) in apploval" 
            :key="'apploval' + index"
            :value="applo"
          >
            {{ applo.value }}
          </option>
        </select>
      </b-col>
      <b-col>
        <b-button
          variant="primary"
          @click="searchApplovalList"
        >
          絞り込み
        </b-button>
      </b-col>
      <b-col 
        md="4" 
        class="text-center"
      >
        <b >
          表示件数：{{ allListCount }}件中{{ displayListCount }}件
        </b>
        <paginate
          :page-count="getPaginateCount"
          :prev-text="'<'"
          :next-text="'>'"
          :click-handler="paginateClickCallback"
          :container-class="'pagination justify-content-center'"
          :page-class="'page-item'"
          :page-link-class="'page-link'"
          :prev-class="'page-item'"
          :prev-link-class="'page-link'"
          :next-class="'page-item'"
          :next-link-class="'page-link'"
          :first-last-button="true"
          :first-button-text="'<<'"
          :last-button-text="'>>'"
          :force-page="currentPage"
        >
        </paginate>
      </b-col>
      <b-col 
        md="2"
      >
        <select 
          class="form-select" 
          name="displayLimits" 
          v-model="display"
        >
          <option 
            v-for="displayLimit in displayLimits" 
            :key="'displayLimit-' +displayLimit.id"
            :value="displayLimit.value"
          >
            {{ displayLimit.text }}
          </option>
        </select>
      </b-col>
      <b-col>
        <b-button 
          variant="primary"
          @click="changeLimit"
        >
          表示件数変更
        </b-button>
      </b-col>
    </b-row>
    <div 
      class="table-responsive " 
    >
      <table 
        class="table table_sticky " 
      >
        <thead 
          class="table-secondary" 
        >
          <tr>
            <th 
              scope="col" 
              style="width:5%"
            >
              <b-button 
                class="btn btn-success text-nowrap" 
                @click="updateNextShootTime()"
              >
                撮影日更新
              </b-button>
            </th>
            <th 
              scope="col" 
              style="width:10%"
            >
              撮影日<br>
              次回撮影予定日
            </th>
            <th 
              scope="col" 
              style="width:10%"
            >
              施設名
            </th>
            <th 
              scope="col" 
              style="width:10%"
            >
              施設詳細名
            </th>
            <th 
              scope="col" 
              style="width:10%"
            >
              状態
            </th>
            <th 
              scope="col" 
              style="width:10%"
            >
              管理者コメント
            </th>
            <th 
              scope="col" 
              style="width:10%"
            >
              施設写真
            </th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="(photoList, index) in photoLists" 
            :key="index"
          >
            <th 
              scope="row"
            >
              {{ index + 1 }}<br>
              <input 
                name="checkUpdateNextShoot"  
                class="form-check-input" 
                type="checkbox"
                :value="photoList.inPhotoID"
                v-model="checkUpdate"
              >
            </th>
            <td>
              {{ photoList.dtPhotoDate }}<br>
              <b-form-input 
                type="date" 
                v-model="photoList.dtNextShootingDate"
              />
            </td>
            <td>
              <router-link 
                :to="{name:'FacilityDetail',
                params : {id: photoList.inFacilityID}}"
              >
                {{ photoList.vcFacilityName }}
              </router-link>
            </td>
            <td>
              {{ photoList.vcFacilityDetailName }}
            </td>
            <td>
              {{ photoList.inApproval }}
            </td>
            <td>
              {{ photoList.txAdComment }}
            </td>
            <td >
              <b-img 
                class="img_link" 
                @click="showImg(photoList.vcFacilityName,photoList.vcFacilityDetailName,photoList.vcStorage)" 
                style="width:100px;height:100px;" 
                :src="photoList.vcStorage"
              ></b-img>
            </td>
          </tr>
        </tbody>
      </table>
      
    </div>
  </b-container>
  </div>
</template>

<script>
import Header from '../../components/Header.vue'
import { Apimixin } from '../../mixins/api'
import moment from 'moment'
import {ja} from 'vuejs-datepicker/dist/locale'
import VuejsDatepicker from 'vuejs-datepicker'

export default {
  title:'マイページ',
  data() {
    return {
      photoLists:[],
      currentPage : 1, 
      limit: 10,
      offset:'',
      toDay:'',
      toMonth:'',
      toDate:'',
      allCount:[],
      applyingCount:[],
      monthPostCount:'',
      totalPostCount:0,
      monthApplyingCount:'',
      totalApplyingCount:0,
      allListCount:'',
      displayListCount:'',
      ja:ja,
      DatePickerFormat:'yyyy-MM',
      searchTo:'',
      searchFrom:'',
      checkUpdate:[],
      searchApploval:{ key:'all',value:'全件' },
      display:'',
      apploval:[
        { key:'all',value:'全件' },
        { key:'unapproved',value:'未承認' },
        { key:'approval',value:'承認' },
        { key:'remand',value:'差し戻し' },
      ]
    }
  },
  mixins:[Apimixin],
  components:{
    Header,
    VuejsDatepicker
  },
  created() {
    this.toDay = moment().format("YYYY-MM")
    this.toMonth = moment().format("YYYY")
    this.toDate = moment().format("MM")
  },
  mounted() {
    //検索条件がセッションストレージにあれば取得
    if(sessionStorage.getItem('myPageSearchApploval') !== null) {
      this.searchApploval = JSON.parse(sessionStorage.getItem('myPageSearchApploval'))
    }
    
    if(sessionStorage.getItem('myPageCurrentPage') !== null) {
      this.display = this.limit
      this.currentPage = Number(sessionStorage.getItem('myPageCurrentPage'))
      this.display = Number(sessionStorage.getItem('myPageLimit'))
      this.limit = Number(sessionStorage.getItem('myPageLimit'))
      let offset = Number(sessionStorage.getItem('myPageOffset'))
      this.getMyPage('all',this.searchApploval.key,offset,this.limit)
    } else {
      this.display = this.limit
      let offset = (this.currentPage - 1) * this.limit;
      sessionStorage.setItem('myPageCurrentPage', this.currentPage)
      sessionStorage.setItem('myPageOffset', offset)
      sessionStorage.setItem('myPageLimit', this.limit)
      this.getMyPage('all',this.searchApploval.key,offset,this.limit)
    }
    
  },
  methods:{
    //ページネーションクリック時現在のページ番号取得
    paginateClickCallback(pageNum) {
      this.currentPage = Number(pageNum);
      sessionStorage.setItem('myPageCurrentPage', this.currentPage)
      this.getLists;
    },
    //承認状況絞り込み
    searchApplovalList() {
      sessionStorage.setItem('myPageSearchApploval', JSON.stringify(this.searchApploval))
      this.currentPage = 1
      let offset = 0
      this.getMyPage('onlyList',this.searchApploval.key,offset,this.limit)
    },
    //写真拡大表示
    showImg(vcFacilityName,vcFacilityDetailName,vcStorage){
      vcStorage =vcStorage.replace('/resizephoto/','/photo/')
      const h = this.$createElement
      const titleVNode = h('div', { domProps: { innerHTML: vcFacilityName+" "+vcFacilityDetailName } })
      const messageVNode = h('div', { class: ['foobar'] }, [
        h('b-img', {
          props: {
            src: vcStorage,
            thumbnail: true,
            center: true,
            fluid: true,
          }
        })
      ])
      this.$bvModal.msgBoxOk([messageVNode], {
        title: [titleVNode],
        buttonSize: 'xl',
        centered: true, size: 'xl'
      })
    },
    //期間絞り込み
    searchCount() {
      this.searchFrom = moment(this.searchFrom).format("YYYY-MM")
      this.searchTo = moment(this.searchTo).format("YYYY-MM")
      this.totalPostCount = 0
      this.totalApplyingCount = 0
      for(let i = 0;i < this.allCount.length; i++) {
        if(this.searchFrom <= this.allCount[i].regist_time 
          && this.searchTo >= this.allCount[i].regist_time) {
            this.totalPostCount += this.allCount[i].totalCount
        }
      }
      for(let i = 0;i < this.applyingCount.length; i++) {
        if(this.searchFrom <= this.applyingCount[i].regist_time 
          && this.searchTo >= this.applyingCount[i].regist_time) {
            this.totalApplyingCount += this.applyingCount[i].totalCount
        }
      }
    },
     //次回撮影日更新
    updateNextShootTime() {
      if(this.checkUpdate.length !== 0) {
        let self =this
        var nextShootingDate = []
        self.checkUpdate.filter(function(value) {
          for(let i = 0;i < self.photoLists.length;i++) {
            if(self.photoLists[i].inPhotoID === value) {
              nextShootingDate.push(self.photoLists[i])
            }
          }
        })
          this.$swal({
            title: '次回撮影日更新',
            html: `次回撮影予定日を更新しますか？`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '確定',
            cancelButtonText:'キャンセル'
          }).then((result) => {
            if (result.isConfirmed) {
              this.$swal({
                title: '処理中',
                html: '処理終了まで画面はそのままにしてください。<b-spinner label="Spinning"></b-spinner>', 
                allowOutsideClick : false , 
                showConfirmButton: false, 
                onBeforeOpen: () => {
                  this.$swal.showLoading()
                }
              }); 
              this.updatePhotoData(nextShootingDate)
            }
          })
      } else {
        this.$swal({
          icon: 'error',
          title:'施設をチェックボックスで選択してください。',
          confirmButtonText:"閉じる"
        })
      }
    },
     //表示件数変更
    changeLimit() {
      this.limit = this.display
      sessionStorage.setItem('myPageLimit', JSON.stringify(this.limit))
      this.currentPage = 1
      this.getLists
    }
  },
  computed: {
    getLists() {
      let offset = (this.currentPage - 1) * this.limit;
      sessionStorage.setItem('myPageOffset', offset)
      sessionStorage.setItem('myPageLimit', this.limit)
      return this.getMyPage('onlyList',this.searchApploval.key,offset, this.limit);
    },
    // 総ページ数の計算
    getPaginateCount() {
      return Math.ceil(this.allListCount / this.limit);
    }
  },
  watch:{
    'currentPage':function() {
      sessionStorage.setItem('myPageCurrentPage', this.currentPage)
    }
  }
}
</script>

<style scoped>
.table_sticky {
  display: block;
  overflow-y: scroll;
  height: calc(45vh);
    
}
.table_sticky thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}
.img_link {
  cursor: pointer;
}
</style>

